// import App from 'next/app';
// import { ThemeProvider } from 'styled-components';
// import { theme } from '../styles/theme';
// import GlobalStyles from '../styles/GlobalStyles';
// import { AuthProvider } from '../context/AuthContext';
// import Head from 'next/head';
// import Layout from '../components/Layout';
// import { wrapper } from '../redux/configureStore';
// const MyApp = ({ Component, pageProps, store }) => {
// 	const onDragEnd = (result) => {
// 		//to do
// 	};
// 	return (
// 		<>
// 			<Head>
// 				{/* <link rel="shortcut icon" href={getStrapiMedia(global.favicon)} /> */}
// 			</Head>
// 			<GlobalStyles />
// 			<ThemeProvider theme={theme}>
// 				<AuthProvider>
// 					<Layout>
// 						<Component {...pageProps} />
// 					</Layout>
// 				</AuthProvider>
// 			</ThemeProvider>
// 		</>
// 	);
// };

// MyApp.getInitialProps = async (ctx) => {
// 	const appProps = await App.getInitialProps(ctx);
// 	return { ...appProps };
// };

// export default wrapper.withRedux(MyApp);
import App from 'next/app';
import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '../styles/theme';
import GlobalStyles from '../styles/GlobalStyles';
import { AuthProvider } from '../context/AuthContext';
import Head from 'next/head';
import Layout from '../components/Layout';
// import { wrapper } from '../redux/configureStore';
import { createWrapper } from 'next-redux-wrapper';
import store from '../redux/store';
import { Provider, useStore, useDispatch, useSelector } from 'react-redux';
import { wrapper } from '../redux/store';
import { compose } from 'redux';
import { getActivities } from '../redux/modules/activities/actions';
import { isAuth } from '../redux/modules/auth/actions';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import Modal from 'react-modal'

const pageVariants = {
	pageInitial: {
		scale: 1.8,

		opacity: 0,
	},
	pageAnimate: {
		scale: 1,

		opacity: 1,
		transition: { duration: 1 },
	},
	pageExit: {
		scale: 1.8,

		opacity: 0,
		transition: { duration: 1 },
	},
	pageInitialHome: {
		opacity: 0,
	},
	pageAnimateHome: {
		opacity: 1,
		transition: { duration: 0 },
	},
	pageExitHome: {
		opacity: 0,
		transition: { duration: 0 },
	},
};

function handleExitComplete() {
	if (typeof window !== 'undefined') {
		window.scrollTo({ top: 0 });
	}
}
const MyApp = ({ Component, pageProps }) => {
	const store = useStore(pageProps.initialReduxState);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getActivities());
		dispatch(isAuth());
	}, []);
	const router = useRouter();
	const pageMotionProps = {
		initial: router.route !== '/' ? 'pageInitial' : 'pageInitialHome',
		animate: router.route !== '/' ? 'pageAnimate' : 'pageAnimateHome',
		exit: router.route !== '/' ? 'pageExit' : 'pageExitHome',
		variants: pageVariants,
	};
	const { bgColor } = useSelector((state) => state.superStyling);

	return (
		<>
			<Head>
				{/* <link rel="shortcut icon" href={getStrapiMedia(global.favicon)} /> */}
			</Head>
			<GlobalStyles bodyBackground={bgColor} />
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<Layout>
						<AnimatePresence exitBeforeEnter>
							<Component {...pageProps} key={router.route} />

						</AnimatePresence>
					</Layout>

				</Provider>
			</ThemeProvider>
		</>
	);
};

export default wrapper.withRedux(MyApp);
