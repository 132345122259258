import React, { useState } from 'react';
import styled from 'styled-components';
import Brand from './Brand';
import BurgerMenu from './BurgerMenu';
import Drawer from './Drawer';
import { signout } from '../../redux/modules/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { LogOut } from '@styled-icons/entypo/LogOut';

const Nav = ({light}) => {
	const [navState, setNavState] = useState(false);
	const dispatch = useDispatch();
	const router = useRouter();
	const handleNav = () => {
		setNavState(!navState);
	};
	const { user } = useSelector((state) => state.auth);
	const { navLight } = useSelector((state) => state.superStyling);
	return (
		<>
			<Wrapper>
				<Brand navLight={navLight}/>
				<Container navLight={navLight}>
					{Object.entries(user).length !== 0 ? (
						<div className="user-info">
							{' '}
							{user.email}
							<div
								className="logout"
								onClick={() => {
									dispatch(signout());
									router.push('/login');
								}}
							>
								<LogOut />
							</div>
						</div>
					) : (
						''
					)}
					<BurgerMenu navState={navState} handleNav={handleNav} navLight={navLight} />
				</Container>
			</Wrapper>

			<Drawer navState={navState} handleNav={handleNav} />
		</>
	);
};
const Wrapper = styled.nav`
	width: 100%;
	padding: 0.6em;
	z-index: 1;
	font-size: 1em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: transparent;
	position: fixed;
	top: 0;
	z-index: 999;
	pointer-eventes: none;
`;
const Container = styled.div`
	display: flex;
	align-items: center;
	color: ${(props) =>  props.navLight?props.theme.colors.text:props.theme.colors.primary};
	.user-info {
		display: flex;
		align-items: center;
		font-size: 0.7em;
		.logout {
			padding: 0.4em;
			margin-left: 6px;
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
`;
export default Nav;
