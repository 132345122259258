import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../redux/modules/activities/actions';
import { CheckCircle } from '@styled-icons/boxicons-regular/CheckCircle';
const backdrop = {
	visible: { opacity: 1, transition: { duration: 0.5 } },
	hidden: { opacity: 0, transition: { duration: 0.5 } },
};

const modalV = {
	hidden: { y: '-60vh', opacity: 0, transition: { duration: 0.5 } },
	visible: {
		y: '20px',
		opacity: 1,
		transition: { duration: 0.5 },
	},
};

const Modal = () => {
	const modal = useSelector((state) => state.activities.modal);
	const [tempModal, setTempModa] = useState(modal);
	const [showTemp, setShowTemp] = useState(false);

	const hoveredOver = () => {
		setShowTemp(true);
	};
	const hoveredOut = () => {
		setShowTemp(false);
	};
	useEffect(() => {
		if (Object.keys(modal).length > 0) {
			setTempModa(modal);
		}
	}, [modal]);
	const icon = (data) => {
		switch (data.type) {
			case 'success':
				return (
					<Icon style={{ color: `green` }}>
						<CheckCircle />
					</Icon>
				);
			default:
				return;
		}
	};
	return (
		<ModalContainer>
			<AnimatePresence>
				{(Object.keys(modal).length > 0 || showTemp) && (
					<motion.div
						className="backdrop"
						variants={backdrop}
						initial="hidden"
						animate="visible"
						exit="hidden"
					>
						<motion.div
							className="modal"
							variants={modalV}
							onMouseEnter={hoveredOver}
							onMouseLeave={hoveredOut}
						>
							{showTemp ? (
								<ModalText>
									{icon(tempModal)}
									{tempModal.message}
								</ModalText>
							) : (
								<ModalText>
									{icon(modal)}
									{modal.message}
								</ModalText>
							)}
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</ModalContainer>
	);
};
const ModalContainer = styled.div`
	.backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10000;
	}
	.modal {
		max-width: 260px;
		margin: 0 auto;
		padding: 1em;
		background: ${(props) => props.theme.colors.white};
		border: 1px solid ${(props) => props.theme.colors.secondary};
		border-radius: 10px;
		text-align: center;
	}
`;
const ModalText = styled.p`
	color: ${(props) => props.theme.colors.primary};
	font-size: 0.8em;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Icon = styled.span`
	padding: 0.4em;
	svg {
		width: 26px;
		height: 26px;
	}
`;
export default Modal;
