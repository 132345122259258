export const menuItems = [
	{
		title: 'Accueil',
		description: 'Projet Pallal',
		link: '/',
		submenu: [
			{ title: 'Contenus', link: '/' },
			{ title: 'Objectifs et approches pédagogiques', link: '/' },
		],
		icon: '/static/images/icons/Introduction-cream.png',
	},
	{
		title: 'Espace collaboratif',
		description: `Communiquer`,
		link: '/',
		submenu: [
			{ title: 'Forum', link: '/' },
			{ title: 'Messagerie et aide', link: '/' },
		],
		icon: '/static/images/icons/Espace-Collaboratif-cream.png',
	},
	{
		title: 'Galerie',
		description:
			'Explorer des images photographiques, cinématographiques et artistiques',
		link: '/',
		icon: '/static/images/icons/Catalogue-cream.png',
	},
	{
		title: 'Méthode commentaire',
		description: 'Apprendre à interpréter une image',
		link: '/',
		icon: '/static/images/icons/Methode-Commentaire-cream.png',
	},
	{
		title: 'Mon musée',
		description:
			'Créer son musée imaginaire : collections multimédias et parcours',
		link: '/',
		icon: '/static/images/icons/Mon-Musee-cream.png',
	},
	{
		title: 'Mon Grand Oral',
		description: 'Préparer son épreuve du Grand Oral',
		link: '/',
		icon: '/static/images/icons/Mon-Oral-cream.png',
	},
	{
		title: 'Outils',
		description: 'Travailler sur l’image avec des outils interactifs',
		link: '/',
		icon: '/static/images/icons/Outiles-cream.png',
	},
	{
		title: 'Glossaire',
		description: 'Décrire et analyser l’image avec du vocabulaire spécialisé',
		link: '/',
		icon: '/static/images/icons/Glossaries-cream.png',
	},
	{
		title: 'Ressources',
		description: 'Approfondir ses connaissances : webographie et bibliographie',
		link: '/',
		icon: '/static/images/icons/Resources-cream.png',
	},
	{
		title: "Activités sur l'image",
		description: 'Voir, comprender et créer',
		link: '/activites',
		submenu: [
			{
				title: 'Photo',
				link: '/activites',
				secondSubmenu: [
					{ title: 'Sanch', link: '/activites/photographie/sanch/' },
					{ title: 'Activité', link: '/' },
					{ title: 'Activité', link: '/' },
					{ title: 'Activité', link: '/' },
				],
			},
			{
				title: 'Cinéma',
				link: '/activites',
				secondSubmenu: [
					{ title: 'Activité', link: '/' },
					{ title: 'Activité', link: '/' },
					{ title: 'Activité', link: '/' },
					{ title: 'Activité', link: '/' },
				],
			},
			{
				title: 'Art',
				link: '/activites',
				secondSubmenu: [
					{ title: 'Activité', link: '/' },
					{ title: 'Activité', link: '/' },
					{ title: 'Activité', link: '/' },
					{ title: 'Activité', link: '/' },
				],
			},
		],
		icon: '/static/images/icons/Activites-cream.png',
	},
];
