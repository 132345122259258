import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';
import Megrim from '../public/static/fonts/Megrim.ttf';
const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: Megrim;
  src: url(${Megrim}) format('truetype');
  font-weight: normal;
  font-style: normal;
}
  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: inherit;
  }
  *:focus {
    outline: none;
  }
  body {
    box-sizing: border-box;
	  font-family: Ubuntu,Open Sans, Helvetica, Arial, Hiragino Sans GB, Microsoft YaHei,
		WenQuanYi Micro Hei, sans-serif;
	  font-size: 100%;
    color: #333;
    background-color: ${(props) =>
			props.bodyBackground ? props.bodyBackground : '#000'};
	  margin: 0;
	  padding: 0;
	  overflow-x: hidden;
	  width: 100%;
  }
  h1,h2{
    font-family:Poiret One,Open Sans, Helvetica, Arial, Hiragino Sans GB, Microsoft YaHei,
		WenQuanYi Micro Hei, sans-serif;
  }
  .pnlm-load-box {
		z-index: -999;
		opacity: 0;
  }
  



    `;

export default GlobalStyle;
