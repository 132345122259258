import React from 'react';
import Nav from './nav/Nav';
import styled from 'styled-components';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Modal from './uiComponents/Modal';
const pageVariants = {
	pageInitial: {
		scale: 1.8,

		opacity: 0,
	},
	pageAnimate: {
		scale: 1,

		opacity: 1,
		transition: { duration: 1 },
	},
	pageExit: {
		scale: 1.8,

		opacity: 0,
		transition: { duration: 1 },
	},
	pageInitialHome: {
		opacity: 0,
	},
	pageAnimateHome: {
		opacity: 1,
		transition: { duration: 0 },
	},
	pageExitHome: {
		opacity: 0,
		transition: { duration: 0 },
	},
};

const Layout = ({ children }) => {
	const router = useRouter();
	const pageMotionProps = {
		initial: router.route !== '/' ? 'pageInitial' : 'pageInitialHome',
		animate: router.route !== '/' ? 'pageAnimate' : 'pageAnimateHome',
		exit: router.route !== '/' ? 'pageExit' : 'pageExitHome',
		variants: pageVariants,
	};
	const visibleNav = useSelector((state) => state.superStyling.visibleNav);

	return (
		<MainContainer>
			{visibleNav ? <Nav /> : ''}
			<Modal />
			<Container>{children}</Container>
		</MainContainer>
	);
};
const MainContainer = styled.div`
	width: 100%;
	// 		height: 100vh;
	//   overflow-y: hidden
	overflow: hidden;
`;
const Container = styled.div`
	margin: 0 auto;
`;
export default Layout;
