import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { motion } from 'framer-motion';
import Image from 'next/image';
const variants = {
	open: {
		y: 0,
		opacity: 1,
		transition: {
			y: { stiffness: 1000, velocity: -100 },
		},
	},
	closed: {
		y: 50,
		opacity: 0,
		transition: {
			y: { stiffness: 1000 },
		},
	},
};
const Card = ({ title, description, link, handleNav, submenu, icon }) => {
	let formatedDesc = description.split('\n').map((i) => {
		return <p key={i}>{i}</p>;
	});
	const renderSubmenu = () => {
		if (submenu) {
			return submenu.map((menuItem, i) => {
				return (
					<li key={i}>
						<Link href={menuItem.link}>
							<a>{menuItem.title}</a>
						</Link>
					</li>
				);
			});
		}
	};

	return (
		<Wrapper
			onClick={handleNav}
			variants={variants}
			whileHover={{ scale: 1.07 }}
			whileTap={{ scale: 0.95 }}
		>
			<Link href={link}>
				<a>
					<h3 className="card-title">{title}</h3>
					{description ? (
						<div className="card-description">{description}</div>
					) : null}
				</a>
			</Link>
			<ul className="submenu">{renderSubmenu()}</ul>
			<Link href={link}>
				<a className="icon">
					<Image src={icon} width={40} height={40} />
				</a>
			</Link>
		</Wrapper>
	);
};

const Wrapper = styled(motion.div)`
	width: 100%;
	max-width: 300px;
	// border: 1px solid ${(props) => props.theme.colors.secondary};
	padding: 0.4em;
	display: flex;
	// justify-content: flex-end;

	flex-direction: column;
	a {
		text-decoration: none;
	}
	transition: all 0.6s;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(4px);
	min-height: 182.5px;
	.card-title {
		padding: 0.4em;
		color: ${(props) => props.theme.colors.text};
		font-family: 'Uncial Antiqua';
		font-size: 1em;
	}
	.card-description {
		padding: 0 0.4em 0.4em 0.4em;
		width: 75%;
		font-size: 0.9em;
		color: ${(props) => props.theme.colors.text};
	}
	.submenu {
		list-style-type: none;
		padding: 0 0.4em 0.4em 0.4em;

		a {
			text-decoration: none;
			color: ${(props) => props.theme.colors.text};
			font-size: 0.8em;
			:before {
				content: '-';
				padding-right: 2px;
			}
		}
	}
	.icon {
		padding: 0.4em;
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}
	.card-link {
		margin-top: auto;
		padding: 0.4em;
		a {
			text-decoration: none;
			border: 1px solid ${(props) => props.theme.colors.secondary};
			padding: 0.4em 0.8em;
			font-size: 1em;
			line-height: 1.5;
			border-radius: 12px;
			color: ${(props) => props.theme.colors.white};
			background-color: ${(props) => props.theme.colors.secondary};
		}
	}
`;

export default Card;
