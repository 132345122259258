import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTransition, useTrail, animated } from 'react-spring';
// import { Link } from 'react-router-dom';
import { menuItems } from './menuItems';
import Card from './Card';
import BigCard from './BigCard';
import { motion, AnimatePresence } from 'framer-motion';
import Image from 'next/image';
const postVariants = {
	initial: { scale: 0, y: -200, opacity: 0 },
	enter: {
		scale: 1,
		y: 0,
		opacity: 1,
		transition: { duration: 0.2, ease: [0.48, 0.15, 0.25, 0.96] },
	},
	exit: {
		y: 200,
		opacity: 0,
		transition: { duration: 0.2, staggerChildren: 0.07, delayChildren: 0.2 },
	},
};
const pageVariants = {
	pageInitial: {
		// x: '100vw',
		scale: 0.2,
	},
	pageAnimate: {
		// x: 0,
		scale: 1,
		transition: { duration: 0.2, type: 'spring', stiffness: 20 },
	},
	pageExit: {
		scale: 1.8,

		opacity: 0,
		transition: {
			delay: 0.6,
			duration: 0.2,
			staggerChildren: 0.1,
		},
	},
};
const drawerVariants = {
	expanded: () => ({
		scale: 1,
		opacity: 1,
		display: 'flex',
		transition: { duration: 0.2 },
		// transition: { duration: 0.2, type: 'spring', stiffness: 20 },
	}),
	collapsed: () => ({
		scale: 1.5,
		opacity: 0,
		display: 'none',
		transition: { duration: 0.2 },
		// transition: { duration: 0.2, type: 'spring', stiffness: 20 },
	}),
};

const Drawer = ({ navState, handleNav }) => {
	const transitions = useTransition(navState, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		config: { duration: 600 },
	});
	const trail = useTrail(menuItems.length, {
		config: { mass: 5, tension: 2000, friction: 200 },
		opacity: navState ? 1 : 0,
		x: navState ? 0 : 20,
		height: navState ? 80 : 0,
		from: { opacity: 0, x: 20, height: 0 },
	});
	useEffect(() => {
		return () => {};
	}, []);
	const findActivity = () => {
		let activity = menuItems.find((item) => item.link === '/activites');
		return (
			<BigCard
				title={activity.title}
				description={activity.description}
				link={activity.link}
				handleNav={handleNav}
				submenu={activity.submenu}
				icon={activity.icon}
			/>
		);
	};
	return (
		<DrawerWrapper
			initial={navState ? 'expanded' : 'collapsed'}
			animate={navState ? 'expanded' : 'collapsed'}
			variants={drawerVariants}
			background={'/static/images/pano-main-bg.jpg'}
		>
			<div className="inside-container">
				<DrawerTitle>
					<Image
						src="/static/images/pallal-logo-sans-frame-2021.png"
						alt="EducMedias"
						width={77}
						height={100}
					/>
				</DrawerTitle>
				<CardContainer
				// variants={postVariants}
				// initial="initial"
				// animate={navState ? 'enter' : 'exit'}
				>
					<div className="cards">
						{menuItems.map((menuItem, i) => {
							if (menuItem.link !== '/activites') {
								return (
									<Card
										key={i}
										title={menuItem.title}
										description={menuItem.description}
										link={menuItem.link}
										handleNav={handleNav}
										submenu={menuItem.submenu}
										icon={menuItem.icon}
									/>
								);
							}
						})}
					</div>
					{findActivity()}
					{/* {trail.map(({ x, height, ...rest }, index) => (
								<AContainer
									key={index}
									className="trails-text"
									style={{
										...rest,
										transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
									}}
								>
									<Card
										style={{ height }}
										title={menuItems[index].title}
										description={menuItems[index].description}
										link={menuItems[index].link}
										handleNav={handleNav}
									/>
								</AContainer>
							))} */}
				</CardContainer>
			</div>
		</DrawerWrapper>
	);
};

const DrawerWrapper = styled(motion.div)`
	background: transparent;
	position: fixed;
	z-index: 998;

	width: 100%;
	padding: 4em 1em;
	background-image: url(${(props) => props.background});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	display: flex;
	justify-content: center;
	.inside-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
		max-width: 1200px;
	}
	@media (min-width: 1024px) {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	@media (max-width: 1024px) {
		position: absolute;
		background-attachment: fixed;

		.inside-container {
			overflow: scrollable;
			display: block;
			margin: 0 auto;
			width: 100%;
			max-width: 1200px;
		}
	}
`;
const DrawerTitle = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 1em 0;
	margin: 1em 0;
	border-bottom: 1px solid ${(props) => props.theme.colors.text};
`;
const CardContainer = styled(motion.div)`
	width: 100%;
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-gap: 1em;

	@media (max-width: 1024px) {
		grid-template-columns: 2fr 1fr;
		justify-content: center;
		align-content: center;
	}
	@media (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
	}
	.cards {
		display: grid;
		grid-gap: 1em;
		grid-template-columns: repeat(3, 1fr);

		@media (max-width: 1024px) {
			grid-template-columns: repeat(3, 1fr);
			margin: 0 auto;
		}
		@media (max-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (max-width: 480px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
`;
const AContainer = styled(animated.div)`
	display: flex;
`;
export default Drawer;
