import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/image';
const Brand = ({navLight}) => {
	return (
		<Link href="/" as={`/`}>
			<a>
				<ImageWrapper>
					<Image
						src={navLight?'/static/images/pallal-logo-icon-2021.png':"/static/images/pallal-logo-icon-green.png"}
						alt="Pallal"
						width={55.5}
						height={50}
					/>
				</ImageWrapper>
			</a>
		</Link>
	);
};
const ImageWrapper = styled.div`
	padding: 0.4em;
`;
export default Brand;
